const getURL = () => {
	if (window.location.href.includes('localhost')) {
		return 'http://localhost:5000';
	}
	let protocol = 'https://';
	let baseUrl = 'onedatabepreprod.azurewebsites.net';
	return protocol.concat(baseUrl);
};

const config = {
	apiURL: getURL(),
};

export default config;
